<template>
  <form @submit.prevent="submitIncident" class="max-w-4xl">
    <div v-if="isDuplicateClaim">
      <div class="bg-pink-100 rounded-lg p-6 mb-2 text-gray-800">
       <div class="grid grid-cols-12 items-center">
        <div class="col-span-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(225 29 72)" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
          </svg>
        </div>
        <div class="col-span-11">
          <p class="font-semibold">Error</p>
          <span>The claim you are trying to file has already been submitted.</span>
        </div>
       </div>
      </div>
    </div>
    <div class="bg-white rounded-lg p-6 text-gray-800 mb-2" v-if="currentAdjuster">
      <div class="mb-1">
        <h3 class="font-bold text-2xl text-gray-700 mb-2">
          Information about your claim
        </h3>
        <p>
          <span class="font-bold text-gray-700">
            Reference Number
          </span>
          <br />
          <span>
            {{ incident.tpaClaimId }}
          </span>
        </p>
      </div>
      <div class="mb-1">
        <span class="font-bold text-gray-700">
          Adjuster Information
        </span>
        <br />
        <span>
        {{ currentAdjuster.fullName || `${currentAdjuster.firstName} ${currentAdjuster.lastName}`}}
        </span>
        <br />
        <span>{{ currentAdjuster.email }}</span>
        <br />
        <span>{{ currentAdjuster.phone }}</span>
      </div>
    </div>
    <div class="bg-white rounded-lg p-6 text-gray-800 mb-2">
      <div class="mb-1">
        <span class="font-bold text-gray-700">
          Contact Information
        </span>
        <br />
        <span>
         <b>Name:</b> {{ incident.contactFirstName }} {{ incident.contactLastName }}
        </span>
        <br />
        <span><b>Email:</b> {{ incident.contactEmail }}</span>
        <br />
        <span><b>Phone:</b> {{ incident.contactPhone }}</span>
      </div>
    </div>
    <div v-if="isOrp ? !incident.id : false" class="bg-white rounded-lg p-6 text-gray-800 mb-4">
      <h2 class="text-3xl font-bold text-gray-700">
        Did the incident happen during rental period?
      </h2>
      <Toggle
        class="mt-4"
        :disabled="isSubmitting || !canEdit"
        v-model="incident.rental" />
    </div>
    <div v-if="isOrp ? (incident.rental === true && !incident.id) : false">
      <div class="bg-white rounded-lg p-6 text-gray-800">
        <p class="text-gray-600 text-xl">
          <span className="font-bold">
            Lula doesn’t cover claims for incidents that occur during rental period.
          </span>
          <br />
          <span>
            You can file these claims directly on your carshare website.
          </span>
        </p>
        <div className="mt-4">
          <button
            className="bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt"
            @click="returnToDashboard">
              Return to Dashboard
          </button>
        </div>
      </div>
    </div>
    <div v-if="isOrp ? (incident.rental === false || incident.id) : true">
      <div class="my-2 p-6 bg-white rounded-lg" v-if="isAdmin && incident.id">
        <h3 class="font-bold text-xl text-gray-800">Eligible Policies </h3>
        <hr class="my-2" />
        <div>
          <div @click="onPolicyClicked(policyType.documentId)" class="border bg-white rounded-md px-4 py-2 m-1 cursor-pointer text-sm flex justify-between items-start hover:bg-gray-100 sm:text-base" v-for="policyType in policyTypes" :key="policyType.number">
            <div>
              <span class="font-bold text-gray-800">{{policyType.name}}</span><br>
              <span class="text-gray-600">{{policyType.number}}</span>
            </div>
            <div class="uppercase font-bold text-white bg-lula-alt rounded-full px-4">
              {{policyType.broker}}
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="bg-white rounded-lg p-6 text-gray-800" v-if="isOrp ? (incident.rental === false) : true">
        <div>
          <div className="flex justify-between">
            <h2 class="text-3xl font-bold text-gray-700">
              <span v-if="incident.id">Details about your claim</span>
              <span v-else>Report a Claim</span>
            </h2>
            <div
              class="uppercase font-bold text-white bg-lula-alt rounded-full px-4 flex items-center"
              v-if="isAdmin && isOrp">
                <span>
                  {{ (account.tenantId || '').toUpperCase() || 'GETAROUND' }}
                </span>
            </div>
          </div>
          <div class="mt-1 text-gray-600" v-if="isAdmin">
            <a :href="accountLink" class="font-bold hover:underline">
              {{ account.businessLegalName || '' }}
            </a>
            <span class="mx-2 text-xl" v-if="incident.submitted">
              &#x2022
            </span>
            <span v-if="incident.submitted">
             Reported on {{ incident.submitted }}
            </span>
          </div>
          <div class="text-gray-700 my-4" v-else>
            <span v-if="incident.id" />
            <span v-else>
              If you’ve been in a vehicle accident, report a claim by
              filling out the form below. We’ll get back to you within 24 hours.
            </span>
          </div>
        </div>
        <div class="text-lg mt-2">
          <div class="border p-4 rounded mr-0 md:mr-4">
            <h3 class="text-xl font-bold text-gray-600">
              Incident information
            </h3>
            <hr class="my-4" />
            <div>
              <div class="flex items-center text-gray-600">
                <label class="block font-bold mb-1 mr-1">
                  Date of the Incident
                </label>
                <div v-tooltip="`
                  Date of the incident and closest
                  time approximation`">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                </div>
              </div>
              <div class="flex">
                <Datepicker
                  class="w-full mr-2"
                  :max-date="todayString"
                  :input-class-name="datePickerClass"
                  :enable-time-picker="false"
                  :disabled="isSubmitting || !canEdit"
                  :hide-input-icon="!!incident.incidentDate"
                  placeholder="     Select a date"
                  format="MM/dd/yyyy"
                  autoApply
                  :closeOnAutoApply="true"
                  v-model="incident.incidentDate"
                  required />
                <Datepicker
                  class="w-full"
                  :input-class-name="datePickerClass"
                  :disabled="incident.incidentDate ? (isSubmitting || !canEdit) : true"
                  placeholder="     Select a time"
                  :max-time="todayTimeMax"
                  :hide-input-icon="!!incident.incidentTime"
                  :is24="false"
                  autoApply
                  :closeOnAutoApply="true"
                  v-model="incident.incidentTime"
                  timePicker>
                  <template #input-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="
                            M12 8v4l3 3m6-3a9 9 0
                            11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </template>
                </Datepicker>
              </div>
            </div>
            <div class="flex flex-col mt-4 md:flex-row">
              <div class="w-full md:mr-4">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1">
                    Vehicle
                  </label>
                  <div v-tooltip="'Vehicle involved in the incident'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <div>
                  <Typeahead
                    :items="vehicles"
                    :min-input-length="0"
                    :item-projection="item => {
                      return (item.key || `${item.year} ${item.make} ${item.model}`)
                        + ` (${item.vin})`;
                    }"
                    @selectItem="selectVehicle"
                    :class="typeaheadClass"
                    :default-item="currentVehicle"
                    placeholder="Select vehicle"
                    v-model="incident.vehicle"
                    :disabled="isSubmitting || !canEdit"
                    />
                  <div class="ml-1 text-base" v-if="currentVehicle">
                    <span
                      class="hover:underline cursor-pointer"
                      @click="onViewVehicle(currentVehicle)">
                        Click to view vehicle
                    </span>
                  </div>
                </div>
              </div>
              <div class="w-full mt-4 md:mt-0" v-if="isTrucks">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1 mr-1">
                    Driver
                  </label>
                  <div v-tooltip="'If driver was involved in this incident'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="-mt-1.5 h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="
                          M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                          1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                          8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                          0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                          100-2 1 1 0 000 2z"
                          clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <div>
                  <Typeahead
                    :items="drivers"
                    :min-input-length="0"
                    :item-projection="item => {
                      return `${item.firstName} ${item.lastName}`;
                    }"
                    @selectItem="selectDriver"
                    :default-item="currentDriver"
                    :class="typeaheadClass"
                    placeholder="Select driver"
                    v-model="incident.driver"
                    :disabled="isSubmitting || !canEdit"
                    />
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="flex items-center text-gray-600">
                <label class="block font-bold mb-1 mr-1">
                  Incident Location / Nearest Address
                </label>
                <div v-tooltip="'Select the closest location to the incident from the dropdown'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="-mt-1.5 h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="
                        M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                        1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                        8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                        0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                        100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
              <div class="grid grid-cols-4">
                <div class="col-span-3 mr-2">
                  <google-autocomplete
                    ref="autoCompleteRef"
                    id="incident-location-input"
                    :classname="googleAutocompleteClass"
                    :disabled="isSubmitting || !canEdit"
                    placeholder="Enter address line 1"
                    @inputChange="addressChange"
                    @placechanged="getAddressData" />
                </div>
                <input
                  class="col-span-1"
                  placeholder="Apt #"
                  type="text"
                  :class="{ 'bg-gray-300': isSubmitting }"
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.locationAddressLineTwo" />
              </div>
            </div>
            <Transition class="mt-2">
              <iframe
                width="100%"
                height="400"
                class="rounded-lg border"
                frameborder="0"
                referrerpolicy="no-referrer-when-downgrade"
                :src="mapPlaceUrl"
                allowfullscreen
                v-if="mapPlaceUrl" />
            </Transition>
          </div>
          <div class="p-4 border mt-4 rounded text-gray-600 md:mr-4" v-if="!incident.isFnolSubmittedToTpa">
            <div>
              <h3 class="font-bold text-xl">
                Contact details
              </h3>
              <span class="text-sm">Please verify your contact info for accuracy. Update it if you prefer NARS Adjustors to reach you differently</span>
            </div>
            <hr class="my-2" />
            <div>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    First Name
                  </label>
                  <input
                    placeholder="Enter first name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.contactFirstName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Last Name
                  </label>
                  <input
                    placeholder="Enter last name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.contactLastName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Email
                  </label>
                  <input
                    placeholder="Enter email"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.contactEmail" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Phone number
                  </label>
                  <input
                    placeholder="Enter phone number"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    ref="contactPhoneInputRef"
                    v-model="incident.contactPhone" />
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 border mt-4 rounded text-gray-600 md:mr-4" v-if="!isLimited && isCars && isDriverDetailsEnabled">
            <div>
              <h3 class="font-bold text-xl">
                Driver details
              </h3>
              <span class="text-sm">
                Who was the driver at the time of the incident?
              </span>
            </div>
            <hr class="my-2" />
            <div>
              <div class="m-1">
                <label class="block font-bold mb-1">
                  Relationship with driver
                </label>
                <select
                  :class="{ 'bg-gray-300': isSubmitting }"
                  :disabled="incident.rental || (isSubmitting || !canEdit)"
                  v-model="incident.driverRelation">
                    <option :value="null" disabled :selected="!incident.driverRelation">
                      Select relationship
                    </option>
                    <option value="renter">
                      Renter
                    </option>
                    <option value="other-party">
                      Other Party
                    </option>
                    <option value="owner">
                      Owner
                    </option>
                    <option value="employee">
                      Employee
                    </option>
                </select>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    First name
                  </label>
                  <input
                    placeholder="Enter first name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.driverFirstName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1">
                  <label class="block font-bold mb-1">
                    Last name
                  </label>
                  <input
                    placeholder="Enter last name"
                    type="text"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    :disabled="isSubmitting || !canEdit"
                    v-model="incident.driverLastName" />
                </div>
                <div class="m-1 col-span-2 md:col-span-1" v-if="incident.driverAddressManual !== true">
                  <label class="block font-bold mb-1">
                    Address
                  </label>
                  <google-autocomplete
                    ref="driverAutoCompleteRef"
                    id="driver-address-input"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    placeholder="Enter address"
                    @inputChange="driverAddressChange"
                    @placechanged="getDriverAddressData" />
                  <div class="w-full flex justify-between">
                    <span />
                    <span class="text-sm cursor-pointer hover:underline" @click="incident.driverAddressManual = true">
                      Enter manually
                    </span>
                  </div>
                </div>
                <div class="m-1" :class="{ 'col-span-2': incident.driverAddressManual }">
                  <label class="block font-bold mb-1">
                    Phone number
                  </label>
                  <input
                    placeholder="Enter phone number"
                    type="text"
                    :disabled="isSubmitting || !canEdit"
                    :class="{ 'bg-gray-300': isSubmitting }"
                    ref="driverPhoneInputRef"
                    v-model="incident.driverPhone" />
                </div>
              </div>
              <div class="m-1 mb-2 col-span-2" v-if="incident.driverAddressManual">
                <div class="grid grid-cols-4">
                  <div class="col-span-3 pr-2">
                    <label class="font-bold">
                      License Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter address line 1"
                      v-model="incident.driverAddressLineOne" />
                  </div>
                  <div class="col-span-1">
                    <label class="font-bold">&nbsp;</label>
                    <input
                      type="text"
                      placeholder="Apt #"
                      v-model="incident.driverAddressLineTwo" />
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-1 mt-2">
                  <input
                    type="text"
                    placeholder="City"
                    v-model="incident.driverCity" />
                  <state-select v-model="incident.driverState" />
                  <input
                    type="text"
                    placeholder="Zipcode"
                    v-model="incident.driverZipcode" />
                </div>
              </div>
              <div class="m-1">
                <label class="block font-bold mb-1">
                  Drivers license number
                </label>
                <div class="w-full grid grid-cols-4">
                  <div class="w-full col-span-3 pr-2">
                    <input
                      placeholder="Enter drivers license number"
                      class="w-full"
                      type="text"
                      :disabled="isSubmitting || !canEdit"
                      :class="{ 'bg-gray-300': isSubmitting }"
                      v-model="incident.driverLicenseNumber" />
                  </div>
                  <div class="w-full col-span-1">
                    <state-select
                      class="w-full"
                      :disabled="isSubmitting || !canEdit"
                      :class="{ 'bg-gray-300': isSubmitting }"
                      v-model="incident.driverLicenseState" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border p-4 rounded mt-4 mr-0 md:mr-4" v-if="!isLimited">
            <h3 class="text-gray-600 text-xl font-bold">
              Incident details
            </h3>
            <hr class="my-4" />
            <div class="grid grid-cols-1text-gray-600 md:grid-cols-3">
              <div class="m-2">
                <label class="block font-bold mb-1">
                  Were there passengers?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.passengers"
                  @update:modelValue="updateInjuredOptions" />
              </div>
              <div class="m-2">
                <label class="block font-bold mb-1">
                  Were there police?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.police" />
              </div>
              <div class="m-2" v-if="isCars && !isOrp">
                <label class="block font-bold mb-1">
                  Was this during a rental?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="isRental" />
              </div>
              <div class="m-2">
                <label class="block font-bold mb-1">
                  Were there injuries?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.hasInjuries"
                  @update:modelValue="updateInjuredOptions"/>
              </div>
              <div class="m-2">
                <label class="block font-bold mb-1">
                  Was anyone else involved?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.hasThirdParty"
                  @update:modelValue="updateInjuredOptions" />
              </div>
              <div class="m-2 md:col-span-3" v-if="isWindshieldInvoiceEnabled">
                <label class="block font-bold mb-1">
                  Is this incident related to a windshield crack?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.windshield" />
              </div>
              <div class="m-2 md:col-span-3">
                <label class="block font-bold mb-1">
                  Is this incident related to a Storm/Natural Disaster?
                </label>
                <Toggle
                  :disabled="isSubmitting || !canEdit"
                  v-model="incident.isNaturalDisaster" />
              </div>
            </div>
            <Transition>
              <div v-if="incident.hasThirdParty">
                <div class="text-gray-600 mt-2">
                  <label class="block font-bold mb-2">
                    Who was involved? (select all that apply)
                  </label>
                  <div
                    v-for="opt in thirdPartyOptions"
                    class="
                      text-base transition-all
                      rounded-md mb-1
                      p-2 pl-4 cursor-pointer"
                    :class="{
                      'border-lula border border-l-8': opt.selected === true,
                      'border': !opt.selected,
                      'bg-gray-300': isSubmitting,
                      'bg-gray-100 hover:bg-gray-200': !isSubmitting,
                    }"
                    :key="opt.key"
                    @click="() => {
                      if (!isSubmitting && canEdit) {
                        opt.selected = !opt.selected
                      }
                    }">
                      {{ opt.label }}
                  </div>
                </div>
                <div class="mt-4" v-if="incident.hasThirdParty">
                  <label class="
                    flex items-center text-gray-600
                    font-bold mb-1 mr-1">
                      <span class="block mr-1">
                        Third Party Information
                      </span>
                      <span class="font-normal text-gray-400 mr-1">
                        (Optional)
                      </span>
                      <div
                        v-tooltip="`
                          Although filling third party section
                          is optional, providing this information
                          will speed up your claim process`">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="
                              M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                              1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                              8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                              0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                              100-2 1 1 0 000 2z"
                              clip-rule="evenodd" />
                        </svg>
                      </div>
                  </label>
                  <ThirdPartySection />
                </div>
              </div>
            </Transition>
            <Transition>
              <div class="text-gray-600 mt-2" v-if="incident.hasInjuries">
                <label class="block font-bold mb-2">
                  Who was injured? (select all that apply)
                </label>
                <div
                  v-for="opt in injuredOptions.filter(opt => opt.show)"
                  class="
                    text-base transition-all
                    rounded-md mb-1
                    p-2 pl-4 cursor-pointer"
                  :class="{
                    'border-lula border border-l-8': opt.selected === true,
                    'border': !opt.selected,
                    'bg-gray-300': isSubmitting,
                    'bg-gray-100 hover:bg-gray-200': !isSubmitting,
                  }"
                  :key="opt.key"
                  @click="() => {
                    if (!isSubmitting && canEdit) {
                      opt.selected = !opt.selected
                    }
                  }">
                    {{ opt.label }}
                </div>
              </div>
            </Transition>
          <div class="mt-4">
            <div class="flex items-center text-gray-600">
              <label class="block font-bold mb-1 mr-1">
                Description of the Incident
                <span class="font-normal text-gray-400">
                  (Minimum 100 Characters)
                </span>
              </label>
            </div>
            <div>
              <textarea
                class="transition py-2 px-4"
                :class="{ 'bg-gray-300': isSubmitting }"
                rows="5"
                :minlength="100"
                :maxlength="4000"
                placeholder="Please describe what happened"
                :disabled="isSubmitting || !canEdit"
                v-model="incident.description" />
              <div class="flex justify-between text-sm -mt-2 text-gray-400">
                <span />
                <span>
                  {{ descriptionCharacterCount }}/4000 characters
                </span>
              </div>
            </div>
            <div v-if="isLoadingFiles">
              <div class="bg-gray-50 p-4 mt-4 rounded text-gray-600 text-center animate-pulse">
                Loading Attachments
              </div>
            </div>
            <div v-else>
              <Transition>
                <div class="mt-4" v-if="incident.windshield">
                  <div class="text-gray-600">
                    <div class="flex items-center">
                      <label class="block font-bold mb-1 mr-1">
                        Paid Invoice
                      </label>
                      <div v-tooltip="`A windshield claim cannot be processed unless this document is included`">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="-mt-1.5 h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              fill-rule="evenodd"
                              d="
                                M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                                1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                                8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                                0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                                100-2 1 1 0 000 2z"
                                clip-rule="evenodd" />
                          </svg>
                      </div>
                    </div>
                    <a
                      :href="windshieldInvoice.url"
                      @click.prevent="
                        downloadFile(
                          windshieldInvoice.url,
                          windshieldInvoice.name
                        )
                      "
                      :download="windshieldInvoice?.name"
                      class="hover:underline"
                      v-if="windshieldInvoice?.object">
                        {{ windshieldInvoice.name }}
                    </a>
                    <IncidentAttachment
                      label="Paid Invoice"
                      @input="(file) => windshieldInvoice = file"
                      v-else />
                  </div>
                </div>
              </Transition>
              <Transition>
                <div class="mt-4" v-if="incident.rental">
                  <div class="text-gray-600">
                    <label class="block font-bold mb-1 mr-1">
                      Rental Agreement
                    </label>
                    <a
                      :href="rentalAgreement.url"
                      @click.prevent="
                        downloadFile(
                          rentalAgreement.url,
                          rentalAgreement.name
                        )
                      "
                      :download="rentalAgreement?.name"
                      class="hover:underline"
                      v-if="rentalAgreement?.object">
                        {{ rentalAgreement.name }}
                    </a>
                    <IncidentAttachment
                      label="Rental Agreement"
                      @input="(file) => rentalAgreement = file"
                      v-else />
                  </div>
                </div>
              </Transition>
              <Transition>
                <div class="mt-4" v-if="incident.police">
                  <div class="text-gray-600">
                    <label class="block font-bold mb-1 mr-1">
                      Police Report
                    </label>
                    <a
                      :href="policeReport.url"
                      @click.prevent="
                        downloadFile(
                          policeReport.url,
                          policeReport.name
                        )
                      "
                      :download="policeReport?.name"
                      class="hover:underline"
                      v-if="policeReport?.object">
                        {{ policeReport.name }}
                    </a>
                    <IncidentAttachment
                      label="Police Report"
                      @input="(file) => policeReport = file"
                      v-else />
                  </div>
                </div>
              </Transition>
              <div class="mt-4">
                <div class="flex items-center text-gray-600">
                  <label class="block font-bold mb-1 mr-1">
                    Claim Attachments
                    <span class="hidden md:inline">
                      (Additional Documents, Damage of Vehicles, etc...)
                    </span>
                    <span class="font-normal text-gray-400">
                      (Optional)
                    </span>
                  </label>
                </div>
                <Attachments
                  ref="claimAttachments"
                  :disabled="isSubmitting || !canEdit" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 p-4 bg-white rounded-lg" v-if="!incident.id">
        <div class="flex flex-col md:flex-row justify-start text-gray-600">
          <label class="mb-1 mr-1">
              Email updates will be sent to:
          </label>
          <label class="block font-bold mb-1 mr-1">
            {{ incident?.contactEmail }}
          </label>

        </div>
        <div class="flex flex-col justify-start justify-between text-gray-600">
            <label class="block mb-1 mr-1 font-bold">
             Do you want to receive updates via text message?
            </label>
            <Toggle
              v-model="incident.isTxtNotificationsOptedIn"
              :disabled="isSubmitting || !canEdit" />
        </div>
        <div v-show="incident.isTxtNotificationsOptedIn" class="flex flex-col justify-start text-gray-600" >
            <label class="block mb-1 mr-1 font-bold">
             Enter the phone number:
            </label>
            <div class="flex flex-col md:flex-row justify-start text-gray-600">
              <input class="p-1 m-1 text-lg w-48 md:w-72"
                ref="textNotificationsPhone"
                type="tel"
                :class="{ 'bg-gray-300': isSubmitting }"
                v-model="incident.txtNotificationPhoneNum"
                :disabled="isSubmitting || !canEdit"
                @keyup ="textNotificationsPhone_change"
                @blur="textNotificationsPhone_blur" />
            </div>
            <div class="text-red-600" v-show="incident.isTextNotificationsPhoneNumberValid == false">
              <span>{{incident.txtNotificationPhoneNumValidationMessage}}</span>
            </div>
            <p class="mt-1 font-weight-400 text-sm">By opting into text message updates, you agree to the terms and privacy policy for recurring automated text messages from Lula via the cell phone number you provided above. Text message and data rates my apply. You can opt out any time by selecting “No”.</p>
        </div>
      </div>
      <div class="mt-2 p-4 bg-white rounded-lg" v-if="isCommentNeeded">
        <label class="text-lg text-gray-600 font-bold">
          Comment
        </label>
        <br />
        <textarea
          :disabled="isSubmitting"
          :class="{ 'bg-gray-300 text-gray-400': isSubmitting }"
          placeholder="You will need to add a comment to save this change"
          v-model="incident.comment" />
      </div>
      <div class="m-4" v-if="validationMessages.length > 0">
        <div class="w-4/5 bg-red-100 mb-2 rounded-lg p-2 text-gray-800 float-right">
          <div class="grid grid-cols-12 items-center">
            <div class="col-span-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(239 68 68)" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
            </div>
            <div class="col-span-11">
             <span class="font-semibold text-red-500">Missing information before you can submit.</span>
             <div class="grid grid-cols-2 gap-2 grid-flow-row">
              <p class="text-red-500" v-for="message in validationMessages">
                &bull; {{ message }}
              </p>
             </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-between mt-2" v-if="!isLimited">
        <span />
        <button
          type="submit"
          class="transition duration-200"
          :class="
            {
              'bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt': canSubmit,
              'bg-gray-300 text-gray-400 font-bold cursor-default': !canSubmit,
            }
          "
          :disabled="!canSubmit"
          v-if="canEdit">
            {{ $store.state.claim?.id ? 'Update Claim' : 'Submit Claim' }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import 'intl-tel-input/build/css/intlTelInput.css';
import { ref, watch, onMounted, computed, onUpdated, inject } from 'vue';
import { useStore } from 'vuex';
import {
  Toggle,
  Datepicker,
  Typeahead,
  GoogleAutocomplete,
  StateSelect,
  Modal,
} from '@getaddify/lula-components';
import { useLDFlag } from 'launchdarkly-vue-client-sdk'
import IncidentAttachment from '@/ClaimForm/IncidentAttachment.vue';
import Attachments from '@/ClaimForm/Attachments.vue';
import ThirdPartySection from '@/ClaimForm/ThirdPartySection.vue';
import axios from 'axios';
import moment from 'moment';
import intlTelInput from 'intl-tel-input';
import IMask from 'imask';
import { setTimeout } from 'timers';

function getRentalAgreement(store) {
  return (store.state.attachments || [])
    .find(att =>
      att.type === 'rental-agreement'
    )
}

function getPoliceReport(store) {
  return (store.state.attachments || [])
    .find(att =>
      att.type === 'police-report'
    )
}

function getWindshieldInvoice(store) {
  return (store.state.attachments || [])
    .find(att =>
      att.type === 'windshield-invoice'
    )
}

function getIncidentFromClaim(claim, thirdParties = [], account) {
  const incidentDate = moment(moment.utc(claim.incidentDate).format('yyyy-MM-DD'), 'yyyy-MM-DD');
  const incidentTime = moment(claim.incidentTime, 'HH:mm');
  const createdDate = moment.utc(claim.created).local();
  return {

    // INCIDENT FIELDS
    id: claim.id,
    incidentDate: claim.incidentDate ? incidentDate.toDate() : null,
    incidentTime: claim.incidentTime ? {
      hours: incidentTime.format('HH'),
      minutes: incidentTime.format('mm'),
    }: null,
    locationAddressLineOne: claim.locationAddressLineOne || '',
    locationAddressLineTwo: claim.locationAddressLineTwo || '',
    locationCity: claim.locationCity || '',
    locationZipcode: claim.locationZipcode || '',
    locationState: claim.locationState || '',
    vehicle: claim.vehicle || null,
    driver: claim.driver || null,
    contactFirstName: claim.contactFirstName || account.contactFirstName || '',
    contactLastName: claim.contactLastName || account.contactLastName || '',
    contactEmail: claim.contactEmail || account.contactEmail || '',
    contactPhone: claim.contactPhone || account.contactNumber || '',
    driverRelation: claim.driverRelation || null,
    driverFirstName: claim.driverFirstName || '',
    driverLastName: claim.driverLastName || '',
    driverAddressLineOne: claim.driverAddressLineOne || '',
    driverAddressLineTwo: claim.driverAddressLineTwo || '',
    driverCity: claim.driverCity || '',
    driverState: claim.driverState || '',
    driverZipcode: claim.driverZipcode || '',
    driverPhone: claim.driverPhone || '',
    driverLicenseNumber: claim.driverLicenseNumber || '',
    driverLicenseState: claim.driverLicenseState || null,
    police: claim.police || false,
    passengers: claim.passengers || false,
    rental: claim.rental || false,
    windshield: claim.windshield || false,
    description: claim.description || '',
    hasInjuries: (claim.injuries || '').length > 0,
    hasThirdParty:
      (claim.thirdParty || '').length > 0 || thirdParties.length > 0,
    isNaturalDisaster: claim.naturalDisaster || false,
    injuries: claim.injuries || '',
    thirdParty: claim.thirdParty || '',
    submitted: claim.created ? createdDate.format('dddd MMMM Do YYYY') : null,
    isTxtNotificationsOptedIn: claim.isTxtNotificationsOptedIn || false,
    txtNotificationPhoneNum: claim.txtNotificationPhoneNum || '',
    // ADMIN FIELDS
    status: claim.status || 'reported',
    tpaClaimId: claim.tpaClaimId || null,
    adjuster: claim.adjuster || null,
    // META FIELDS
    comment: '',
    '__isSubmitting': false,
    isTextNotificationsPhoneNumberValid: true,
    txtNotificationPhoneNumPlaceHolder: '',
    txtNotificationPhoneNumValidationMessage: '',
    started: moment.utc(Date.now()).toISOString(),
    isFnolSubmittedToTpa: claim.isFnolSubmittedToTpa || false
  }
}

export default {
  components: {
    Toggle,
    Typeahead,
    IncidentAttachment,
    Attachments,
    Datepicker,
    StateSelect,
    GoogleAutocomplete,
    ThirdPartySection,
    Modal,
  },
  setup() {

    const store = useStore();

    const mode = inject('mode') || 'cars';
    const isAdmin = mode.includes('admin_');
    const isOrp = mode.includes('orp_');
    const isLimited = mode.includes('limited_');
    const tenantId = inject('tenantId') || null;
    const mapsApiKey = inject('mapsApiKey') || null;
    const showMessage = inject('showMessage') || (() => null);
    const onClaimSubmit = inject('onClaimSubmit') || (() => null);
    const onViewVehicle = inject('onViewVehicle') || (() => null);
    const accountLink = inject('accountLink') || '#';
    const account = inject('account') || {};
    const vehicles = inject('vehicles') || [];
    const adjusters = JSON.parse(inject('adjustersJson') || '[]');
    const drivers = inject('drivers') || [];
    const onPolicyClicked = inject('onPolicyClicked') || (() => null);
    const isDriverDetailsEnabled = useLDFlag('Claims.DriverDetails', false);
    const isWindshieldInvoiceEnabled = useLDFlag('Claims.WindshieldInvoice', false);
    const isDuplicateClaimEnabled = useLDFlag('Claims.DuplicateClaim', false);

    const captchaToken = inject('captchaToken') || null;
    const isVerified = ref(captchaToken ? false : true);

    async function validatePhoneNumber(phoneNumber) {
      const result = await store.dispatch(
                'validatePhoneNumber',
                { phoneNumber: phoneNumber}
              );
      return result;
    }
    async function validateTextNotificationsPhoneNumber(){
        if(incident.value.isTxtNotificationsOptedIn)
        {
          const phoneNumber = textNotiifcationsPhoneInput.getNumber()
          const validationResult = await validatePhoneNumber(phoneNumber);
          incident.value.isTextNotificationsPhoneNumberValid = validationResult.isValid && validationResult.isMobile
          if(!validationResult.isValid)
            incident.value.txtNotificationPhoneNumValidationMessage = 'We were unable to verify this phone number. Please check the number again'
          else if(validationResult.isValid && !validationResult.isMobile)
            incident.value.txtNotificationPhoneNumValidationMessage = 'We accept only mobile phones. Please provide a mobile number'
        }
        else
          incident.value.isTextNotificationsPhoneNumberValid = true;
      }


    const currentVehicle = computed(() => {
      return vehicles.find(vin => {
        return vin.entityId === incident.value.vehicle;
      });
    });

    onMounted(() => {
      if (captchaToken) {
        captchaToken.subscribe({
          next(token) {
            isVerified.value = token ? true : false;
          }
        });
      }
    });

    const incident = ref(getIncidentFromClaim(
      { ...store.state.claim, rental: isOrp ? false : store.state.claim.rental },
      store.state.thirdParties,
      account
    ));

    const isRental = ref(incident.value.rental || false);

    watch(isRental, () => {
      incident.value.rental = isRental.value;
      incident.value.driverRelation = isRental.value ? 'renter' : null;
    });

    const isDuplicateClaim = ref(false);

    watch(() => [incident.value.vehicle, incident.value.incidentDate], async ([newVehicleId, newIncidentDate], [prevVehicleId, prevIncidentDate]) => {
      console.log('watch');
      if((newVehicleId && newIncidentDate) && (newVehicleId != prevVehicleId || newIncidentDate != prevIncidentDate)) {
        if(!isDuplicateClaimEnabled.value) return;
        isDuplicateClaim.value = await store.dispatch(
                  'checkForDuplicateClaim',
                  { tenantId, incidentDate: moment(incident.value.incidentDate).format('yyyy-MM-DD'), vehicleId: incident.value.vehicle}
                );
      }
    });

    const injuredOptions = ref([
       {
          label: 'Driver of my vehicle',
          key: 'driver',
          show: true,
          selected: false
       },
       {
          label: 'One or more passengers in my vehicle',
          key: 'passenger',
          show: true,
          selected: false
       },
       {
          label: 'Driver of another vehicle',
          key: 'another-driver',
          show: true,
          selected: false
       },
       {
          label: 'One or more passengers in another vehicle',
          key: 'another-passenger',
          show: true,
          selected: false
       },
       {
          label: 'Pedestrian',
          key: 'pedestrian',
          show: true,
          selected: false
       }
    ]);

    const thirdPartyOptions = ref([
      {
        label: 'Renter',
        key: 'renter',
        mode: 'cars',
        selected: false,
      },
      {
        label: 'Third Party Driver',
        key: 'driver',
        selected: false,
      },
      {
        label: 'Pedestrian',
        key: 'pedestrian',
        selected: false,
      },
      {
        label: 'Passenger (of Insured)',
        key: 'passenger-insured',
        selected: false,
      },
      {
        label: 'Passenger (of Third Party)',
        key: 'passenger-third-party',
        selected: false,
      },
      {
        label: 'Witness',
        key: 'witness',
        selected: false,
      },
      {
        label: 'Other',
        key: 'other',
        selected: false,
      }
    ].filter(tp => {
      if ('mode' in tp) {
        return mode.includes(tp.mode);
      }
      return true;
    }));

    const contactPhoneInputRef = ref(null);
    const contactTelInput = ref(null);
    onMounted(() => {
      if (!contactPhoneInputRef.value) {
        return
      }
      contactTelInput.value = intlTelInput(contactPhoneInputRef.value, {
        utilsScript: "/intlUtils.js",
        customContainer: 'w-full',
        nationalMode: true,
      });
      if (contactPhoneInputRef.value.value) {
        contactTelInput.value.setNumber(contactPhoneInputRef.value.value);
        const mask = IMask(contactPhoneInputRef.value, { mask: `(000) 000-0000` });
        contactPhoneInputRef.value.addEventListener('keyup', () => {
          mask.updateOptions({ mask: `(000) 000-0000` });
        });
      }
    });

    const driverPhoneInputRef = ref(null);
    const driverTelInput = ref(null);
    onMounted(() => {
      if (!driverPhoneInputRef.value) {
        return
      }
      driverTelInput.value = intlTelInput(driverPhoneInputRef.value, {
        utilsScript: "/intlUtils.js",
        customContainer: 'w-full',
        nationalMode: true,
      });
      if (driverPhoneInputRef.value.value) {
        driverTelInput.value.setNumber(driverPhoneInputRef.value.value);
        const countryData = driverTelInput.value.getSelectedCountryData();
        const mask = IMask(driverPhoneInputRef.value, { mask: `(000) 000-0000` });
        driverPhoneInputRef.value.addEventListener('keyup', () => {
          const countryData = driverTelInput.value.getSelectedCountryData();
          mask.updateOptions({ mask: `(000) 000-0000` });
        });
      }
    });

    onUpdated(() => {
      if (driverTelInput.value) {
        driverTelInput.value.setNumber(incident.value.driverPhone || '');
      }
    });

    onUpdated(() => {
      if (contactTelInput.value) {
        contactTelInput.value.setNumber(incident.value.contactPhone || '');
      }
    });

    const currentAdjuster = computed(() => {
      if (!incident.value.adjuster)
        return null;
      return adjusters.find(adj => adj.id === incident.value.adjuster);
    })

    const initialIncident = ref({ ...incident.value });
    const initialInjuries = ref([ ...injuredOptions.value ].map(x => x.selected));
    const initialThirdParties = ref([ ...thirdPartyOptions.value ].map(x => x.selected));
    const initialThirdPartyEntities = ref((store.state.thirdParties || []).map(x => ({ ...x })));

    const setInitialValues = () => {
      initialIncident.value.comment = '';
      initialIncident.value = { ...incident.value };
      initialInjuries.value = injuredOptions.value.map(x => x.selected);
      initialThirdParties.value = [ ...thirdPartyOptions.value ].map(x => x.selected);
      initialThirdPartyEntities.value = (store.state.thirdParties || []).map(x => ({ ...x }));
    }

    const isLoadingFiles = ref(false);
    const rentalAgreement = ref(getRentalAgreement(store));
    const policeReport = ref(getPoliceReport(store));
    const windshieldInvoice = ref(getWindshieldInvoice(store))


    let textNotiifcationsPhoneInput;
    const textNotificationsPhone = ref(null);


    onMounted(async () => {
      if (store.state.claim?.id == null) {
        return;
      }
      isLoadingFiles.value = true;
      await store.dispatch('getAttachmentsForClaim', {
        tenantId,
        claimId: store.state.claim.id,
      });
      rentalAgreement.value = getRentalAgreement(store);
      policeReport.value = getPoliceReport(store);
      windshieldInvoice.value = getWindshieldInvoice(store);
      isLoadingFiles.value = false;
    });
    const updateInjuredOptions = () => {
        injuredOptions.value.filter(opt => opt.key === 'passenger' || opt.key === 'another-passenger').forEach(opt => opt.show = incident.value.passengers);
        injuredOptions.value.find(opt => opt.key === 'pedestrian').show = incident.value.hasThirdParty;
      };
    onMounted(() => {
      if (incident.value.injuries) {
        const currentInjuries = incident.value.injuries.split(',');
        injuredOptions.value = injuredOptions.value.map(io => {
          return {
            ...io,
            selected: currentInjuries.includes(io.key),
          }
        });
      }
      if (incident.value.thirdParty) {
        const currentThirdParty = incident.value.thirdParty.split(',');
        thirdPartyOptions.value = thirdPartyOptions.value.map(io => {
          return {
            ...io,
            selected: currentThirdParty.includes(io.key),
          }
        });
      }
      setInitialValues();
      if (textNotificationsPhone.value) {
        textNotiifcationsPhoneInput = intlTelInput(textNotificationsPhone.value, {
          utilsScript: "/intlUtils.js",
          onlyCountries: ["us"],
          formatOnDisplay: true
        });
        var maskOptions = {
          mask: '(000) 000-0000'
        };
        var mask = IMask(textNotificationsPhone.value, maskOptions);
      }
      updateInjuredOptions();
    });


    onUpdated(() => {
      if (textNotiifcationsPhoneInput) {
        textNotiifcationsPhoneInput.setNumber(incident.value.txtNotificationPhoneNum || '');
      }
    });


    const toggleSubmitting = () => {
      store.commit('setItem', {
        key: 'isSubmittingForm',
        value: !store.state.isSubmittingForm
      });
    }

    const hasChanged = computed(() => {
      if(Object.keys(initialIncident.value).find(key =>
        initialIncident.value[key] !== incident.value[key]
      ))
        return true
      for (let x = 0; x < initialInjuries.value.length; x++) {
        if (initialInjuries.value[x] !== injuredOptions.value[x].selected) {
          return true;
        }
      }
      for (let x = 0; x < initialThirdParties.value.length; x++) {
        if (initialThirdParties.value[x] !== thirdPartyOptions.value[x].selected) {
          return true;
        }
      }
      if((store.state.thirdParties || []).find(thirdParty => {
        return !initialThirdPartyEntities.value.find(tp => {
          return JSON.stringify(tp) === JSON.stringify({ ...thirdParty });
        });
      })) {
        return true;
      }
      if (rentalAgreement.value && !rentalAgreement.value.object) {
        return true;
      }
      if ((store.state.attachments || []).find(a => !a.object)) {
        return true;
      }
      return false;
    });

    const isCommentNeeded = computed(() => {
        if (!isAdmin) {
          return false;
        }
        if (!store.state.claim?.id) {
          return false;
        }
        return hasChanged.value;
      })

    const canSubmit = computed(() => {
      if (!isVerified.value)
        return false;
      if (!hasChanged.value)
        return false;
      if (!currentVehicle.value)
        return false;
      if(isDuplicateClaim.value)
        return false;
      if ((incident.value.description ?? '').trim().length < 100)
        return false;
      if (isCommentNeeded.value && !incident.value.comment)
        return false;
      if (incident.value.rental && !rentalAgreement.value)
        return false;
      if (incident.value.windshield && !windshieldInvoice.value)
        return false;
      if (isDriverDetailsEnabled.value) {
        if (incident.value.rental && !incident.value.driverRelation)
          return false;
        if (incident.value.driverRelation && !(incident.value.driverFirstName && incident.value.driverLastName))
          return false;
      }
      return !store.state.isSubmittingForm &&
        incident.value.vehicle &&
        incident.value.locationAddressLineOne &&
        incident.value.incidentDate &&
        incident.value.description
    });

    const validationMessages = computed(() => {
      const messages = [];
      if (!currentVehicle.value)
        messages.push('Vehicle must be selected');
      if(isDuplicateClaim.value)
        messages.push('Duplicate claim');
      if ((incident.value.description ?? '').trim().length < 100)
        messages.push('Incident description must be 100 characters or more');
      if (isCommentNeeded.value && !incident.value.comment)
        messages.push('Comment must be provided');
      if (incident.value.rental && !rentalAgreement.value)
        messages.push('Rental agreement must be provided');
      if (incident.value.windshield && !windshieldInvoice.value)
        messages.push('Windshield invoice must be provided');
      if (isDriverDetailsEnabled.value) {
        if (incident.value.rental && !incident.value.driverRelation)
          messages.push('Driver relation must be selected');
        if (incident.value.driverRelation && !(incident.value.driverFirstName && incident.value.driverLastName))
          messages.push('Driver firstName and lastName must be provided');
      }
      if (!isVerified.value)
        messages.push('Not verified');
      return messages.length > 4 ? messages.slice(0,4) : messages;
    });

    const mapPlaceId = ref(null);
    const autoCompleteRef = ref(null);
    onMounted(() => {
      if (!incident.value.id) {
        return;
      }
      if (!autoCompleteRef.value) {
        return;
      }
      if (!incident.value.locationAddressLineOne) {
        return;
      }
      autoCompleteRef.value.update([
        incident.value.locationAddressLineOne,
        incident.value.locationCity,
        incident.value.locationZipcode,
        incident.value.locationState
      ].join(', '));
    });

    const driverAutoCompleteRef = ref(null);
    onMounted(() => {
      if (!incident.value.id) {
        return;
      }
      if (!driverAutoCompleteRef.value) {
        return;
      }
      if (!incident.value.driverAddressLineOne) {
        return;
      }
      driverAutoCompleteRef.value.update([
        incident.value.driverAddressLineOne,
        incident.value.driverCity,
        incident.value.driverZipcode,
        incident.value.driverState,
      ].join(', '));
    });

    const claimAttachments = ref(null);

    return {
      isAdmin,
      isOrp,
      isLimited,
      isLoadingFiles,
      isDriverDetailsEnabled,
      isWindshieldInvoiceEnabled,
      claimAttachments,
      isRental,
      incident,
      canSubmit,
      validationMessages,
      injuredOptions,
      thirdPartyOptions,
      account,
      vehicles,
      drivers,
      accountLink,
      policeReport,
      rentalAgreement,
      windshieldInvoice,
      autoCompleteRef,
      driverAutoCompleteRef,
      driverPhoneInputRef,
      contactPhoneInputRef,
      onViewVehicle,
      onPolicyClicked,
      textNotificationsPhone,
      currentAdjuster,
      isDuplicateClaim,
      isCars: computed(() => {
        return mode.includes('cars');
      }),
      isTrucks: computed(() => {
        return mode.includes('trucks');
      }),
      isCommentNeeded,
      descriptionCharacterCount: computed(() => {
        if (incident.value.description) {
          return `${incident.value.description}`.length;
        }
        return 0;
      }),
      mapPlaceUrl: computed(() => {
        if (!mapsApiKey) {
          return null;
        }
        const baseUrl =
          'https://www.google.com/maps/embed/v1/place';
        if (mapPlaceId.value) {
          return `${baseUrl}?key=${mapsApiKey}&q=place_id:${mapPlaceId.value}`;
        }
        if (incident.value.locationAddressLineOne) {
          const query = [
            incident.value.locationAddressLineOne,
            incident.value.locationCity,
            incident.value.locationZipcode,
            incident.value.locationState
          ].join(', ');
          return `${baseUrl}?key=${mapsApiKey}&q=${escape(query)}`;
        }
        return null;
      }),
      canEdit: computed(() => {
        if (isLimited) {
          return false;
        }
        if (isAdmin) {
          return true;
        }
        if (store.state.claim?.id) {
          return store.state.claim.status === 'reported';
        }
        return true;
      }),
      isSubmitting: computed(() => {
        return store.state.isSubmittingForm;
      }),
      todayString: computed(() => {
        const today = moment();
        return today.format('yyyy-MM-DD');
      }),
      todayTimeMax: computed(() => {
        const today = moment();
        const incidentDate = moment(incident.value.incidentDate);
        if (incidentDate.format('yyyy-MM-DD') === today.format('yyyy-MM-DD'))
          return { hours: parseInt(today.format('H')), minutes: parseInt(today.format('m')) };
        return null;
      }),
      driverAddressChange({ newVal }) {
        if (!newVal.trim()) {
          incident.value = {
            ...incident.value,
            driverAddressLineOne: '',
            driverCity: '',
            driverZipcode: '',
            driverState: ''
          }
        }
      },
      addressChange({ newVal }) {
        if (!newVal.trim()) {
          mapPlaceId.value = null;
          incident.value = {
            ...incident.value,
            locationAddressLineOne: '',
            locationCity: '',
            locationZipcode: '',
            locationState: ''
          }
        }
      },
      getDriverAddressData(address) {
        incident.value = {
          ...incident.value,
          driverAddressLineOne: `${address['street_number'] || ''} ${address['route'] || ''}`,
          driverCity: address['locality'] || '',
          driverZipcode: address['postal_code'] || '',
          driverState: address['administrative_area_level_1'] || '',
        }
      },
      getAddressData(address, place) {
        if (place['place_id']) {
          mapPlaceId.value = place['place_id'];
        }
        incident.value = {
          ...incident.value,
          locationAddressLineOne: `${address['street_number'] || ''} ${address['route'] || ''}`,
          locationCity: address['locality'] || '',
          locationZipcode: address['postal_code'] || '',
          locationState: address['administrative_area_level_1'] || '',
        }
      },
      googleAutocompleteClass: computed(() => {
        let className = `
          w-full transition py-2 px-4
        `;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300';
        }
        return className;
      }),
      typeaheadClass: computed(() => {
        let className = `
          transition py-2 px-4
        `;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300 cursor-default';
        }
        return className;
      }),
      datePickerClass: computed(() => {
        let className = `
          transition w-full bg-gray-100 rounded-lg text-gray-700
          border hover:border text-lg p-2 md:mr-4`;
        if (incident.value['__isSubmitting']) {
          className += ' bg-gray-300 cursor-default';
        }
        return className;
      }),
      selectDriver(driver) {
        incident.value.driver = driver.entityId;
      },
      selectVehicle(vehicle) {
        incident.value.vehicle = vehicle.entityId;
      },
      currentDriver: computed(() => {
        return drivers.find(drv => {
          return drv.entityId === incident.value.driver;
        })
      }),
      currentVehicle,
      async downloadFile(url, name) {
        const download = await axios({ url, responseType: 'blob' });
        const downloadUrl = URL.createObjectURL(download.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      },
      returnToDashboard() {
        window.location.href = '/';
      },
      policyTypes: computed(() => {
        return store.state.policyTypes || [];
      }),
      async textNotificationsPhone_change(event){
        const placeHolder = textNotificationsPhone.value.placeholder;
        if(textNotificationsPhone.value.value.replace(/\D/g, '').length >= placeHolder.replace(/\D/g, '').length)
        {
          setTimeout(async ()=> {
            await validateTextNotificationsPhoneNumber();
          }, 250);
        }
        else
          incident.value.isTextNotificationsPhoneNumberValid = true;
      },
      async textNotificationsPhone_blur(event){
        const placeHolder = textNotificationsPhone.value.placeholder;
        if(textNotificationsPhone.value.value.replace(/\D/g, '').length ===0)
        {
          incident.value.txtNotificationPhoneNumValidationMessage = 'Mobile number is required';
          incident.value.isTextNotificationsPhoneNumberValid = false;
        }
        else if(textNotificationsPhone.value.value.replace(/\D/g, '').length < placeHolder.replace(/\D/g, '').length)
        {
          incident.value.txtNotificationPhoneNumValidationMessage = 'Please check the mobile number format';
          incident.value.isTextNotificationsPhoneNumberValid = false;
        }
        else
          await validateTextNotificationsPhoneNumber();
      },
      async submitIncident() {
        if (!canSubmit.value) {
          return;
        }
        if (!isVerified.value) {
          return;
        }
        toggleSubmitting();
        try {
          const comment = incident.value.comment;
          const incidentDate =
            moment(incident.value.incidentDate).local();
          const incidentTime =
            moment().local();
          if (incident.value.incidentTime) {
            incidentTime.hours(incident.value.incidentTime.hours);
            incidentTime.minutes(incident.value.incidentTime.minutes);
          }
          if (textNotiifcationsPhoneInput && incident.value.isTxtNotificationsOptedIn)
            incident.value.txtNotificationPhoneNum =  textNotiifcationsPhoneInput.getNumber();
          else
            incident.value.txtNotificationPhoneNum = '';
          if (driverTelInput.value)
            incident.value.driverPhone = driverTelInput.value.getNumber();
          if (contactTelInput.value)
            incident.value.contactPhone = contactTelInput.value.getNumber();
          const claimId = store.state.claim?.id;
          const claim = await store.dispatch('saveClaim', {
            ...incident.value,
            incidentDate: moment(incidentDate).format('yyyy-MM-DD'),
            incidentTime: incident.value.incidentTime ?
              incidentTime.format('HH:mm') : null,
            injuries: incident.value.hasInjuries ? injuredOptions.value
              .filter(io => io.show && io.selected)
              .map(io => io.key)
              .join(',') : null,
            thirdParty: incident.value.hasThirdParty ? thirdPartyOptions.value
              .filter(io => io.selected)
              .map(io => io.key)
              .join(',') : null,
            id: claimId,
            tenantId,
          });
          if (!claim) {
            showMessage('Failed to submit claim', true);
            return;
          }
          await Promise.all((store.state.thirdParties || []).map(tp => {
            return store.dispatch(
              'saveThirdPartyForClaim',
              {
                ...tp,
                id: tp.id.length < 36 ? null : tp.id,
                tenant: tenantId,
                comment: comment
              },
            );
          }));
          await Promise.all((store.state.attachments || []).map(attachment => {
            if (!attachment.object) {
              return store.dispatch(
                'saveAttachmentForClaim',
                { claimId: store.state.claim.id, tenantId, attachment }
              );
            }
            return null;
          }));
          if (rentalAgreement.value && !rentalAgreement.value?.object) {
            await store.dispatch('saveAttachmentForClaim', {
              tenantId,
              claimId: store.state.claim.id,
              attachment: {
                type: 'rental-agreement',
                name: rentalAgreement.value.name,
                file: rentalAgreement.value,
              }
            });
          }
          if (policeReport.value && !policeReport.value?.object) {
            await store.dispatch('saveAttachmentForClaim', {
              tenantId,
              claimId: store.state.claim.id,
              attachment: {
                type: 'police-report',
                name: policeReport.value.name,
                file: policeReport.value,
              }
            });
          }
          if (windshieldInvoice.value && !windshieldInvoice.value?.object) {
            await store.dispatch('saveAttachmentForClaim', {
              tenantId,
              claimId: store.state.claim.id,
              attachment: {
                type: 'windshield-invoice',
                name: windshieldInvoice.value.name,
                file: windshieldInvoice.value,
              }
            });
          }
          await store.dispatch(
            'getThirdPartiesForClaim',
            { claimId: store.state.claim.id, tenantId }
          );
          await store.dispatch(
            'getAttachmentsForClaim',
            { claimId: store.state.claim.id, tenantId }
          );
          rentalAgreement.value = getRentalAgreement(store);
          policeReport.value = getPoliceReport(store);
          windshieldInvoice.value = getWindshieldInvoice(store);
          await claimAttachments.value.loadAttachments();
          setInitialValues();
          onClaimSubmit(claim);
          if (!isAdmin && !claimId) {
            store.commit('setStep', 'confirmation');
          }
        } catch(err) {
          console.log(err);
          await store.dispatch('sendError', err);
          showMessage('Failed to submit claim', true);
        } finally {
          toggleSubmitting();
        }
      },
      updateInjuredOptions
    }

  }
}
</script>

<style scoped>
.iti__flag {background-image: url("/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/flags@2x.png");}
}

.v-enter-active,
.v-leave-active {
  transition: opacity .25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
