<template>
  <form @submit.prevent="saveThirdParty" class="bg-white p-6 rounded-lg">
    <div class="flex justify-between">
      <h3 class="text-gray-700 text-base font-bold md:text-4xl">
        Third Party Information
      </h3>
      <span
        @click="removeThirdParty"
        class="
          text-red-500 font-bold text-sm
          cursor-pointer hover:underline md:text-base">
            Remove
      </span>
    </div>
    <div class="mt-4 text-lg text-gray-800">
      <div class="my-4">
        <label class="block font-bold text-gray-600 mb-1">
          Select Third Party Role
        </label>
        <select
          v-model="thirdPartyType"
          :class="{
            'bg-gray-300 text-gray-500 cursor-default': isSubmitting
          }"
          :disabled="isSubmitting">
            <option disabled :value="null">
              Select Third Party Role
            </option>
            <option
              v-for="role in thirdPartyRoles"
              :value="role.key"
              :key="role">
                {{ role.label }}
            </option>
        </select>
      </div>
      <div v-if="thirdPartyType !== null">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div
            class="mb-4"
            :class="{
              'md:mr-4': idx % 2 === 0 && !field.span,
              'md:col-span-2': field.span,
              'hidden': field.hidden,
            }"
            v-for="(field, idx) in fields"
            :key="field.key">
              <div v-if="field.type === 'placeholder'" />
              <div v-else>
                <label
                  class="block font-bold text-gray-600 mb-1">
                  {{ field.label }}
                </label>
                <google-autocomplete
                  :ref="`${field}AutoCompleteRef`"
                  :id="`${field.key}-address-input`"
                  :classname="googleAutocompleteClass"
                  :disabled="isSubmitting"
                  placeholder="Enter address line 1"
                  @inputChange="addressChange"
                  @placechanged="getAddressData"
                  v-if="field.type === 'address'" />
                <textarea
                  :placeholder="field.placeholder"
                  :rows="3"
                  :class="{ 'bg-gray-300 text-gray-500 cursor-default': isSubmitting }"
                  :disabled="isSubmitting"
                  v-model="thirdParty[field.key]"
                  v-else-if="field.type === 'textarea'" />
                <Toggle
                  v-model="thirdParty[field.key]"
                  v-else-if="field.type === 'checkbox'" />
                <input
                  :type="field.type"
                  :placeholder="field.placeholder"
                  :class="{ 'bg-gray-300 text-gray-500 cursor-default': isSubmitting }"
                  :disabled="isSubmitting"
                  v-model="thirdParty[field.key]"
                  v-else />
              </div>
          </div>
        </div>
        <Attachments
          :disabled="isSubmitting" />
      </div>
    </div>
    <div v-if="thirdPartyType">
      <hr class="my-4" />
      <div class="flex justify-between">
        <span />
        <button
          class="
            transition-all font-bold"
          :class="{
            'bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt': canSubmit,
            'bg-gray-300 text-gray-400 font-bold cursor-default': !canSubmit,
          }"
          type="submit"
          :disabled="!canSubmit">
            Save Third Party
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, watch, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { GoogleAutocomplete, Toggle } from '@getaddify/lula-components';
import Attachments from './Attachments.vue';

export default {
  props: [
    'id'
  ],
  emits: [
    'close'
  ],
  components: {
    Toggle,
    GoogleAutocomplete,
    Attachments,
  },
  setup(props, { emit }) {

    const store = useStore();

    const mode = inject('mode') || 'cars';
    const tenantId = inject('tenantId') || null;
    const isSubmitting = ref(false);
    const showMessage = inject('showMessage') || (() => null);
    const thirdParty = ref(store.state.thirdParties.find(tp => {
      return tp.id === props.id;
    }) || { type: null });
    const thirdPartyType = ref(thirdParty.value.type);

    watch(thirdPartyType, () => {
      ['location', 'license']
        .forEach(field => {
          setTimeout(() => {
            const addressInput = document.getElementById(`${field}-address-input`);
            if (!addressInput.value) {
              addressInput.value = ['AddressLineOne', 'City', 'State', 'Zipcode', 'Country']
                .map(aField => thirdParty.value[`${field}${aField}`])
                .filter(aVal => aVal)
                .join(',');
            }
          }, 0);
        });
    });

    onMounted(() => {
      if (thirdPartyType.value) {
        ['location', 'license']
          .forEach(field => {
            setTimeout(() => {
              const addressInput = document.getElementById(`${field}-address-input`);
              if (!addressInput.value) {
                addressInput.value = ['AddressLineOne', 'City', 'State', 'Zipcode', 'Country']
                  .map(aField => thirdParty.value[`${field}${aField}`])
                  .filter(aVal => aVal)
                  .join(', ');
              }
            }, 0);
          });
      }
    });

    return {
      thirdParty,
      thirdPartyType,
      isSubmitting,
      role: ref(null),
      canSubmit: computed(() => {
        return !isSubmitting.value && Object.keys(thirdParty.value)
          .filter(v => v !== 'type' && v !== 'injured')
          .find(v => thirdParty.value[v]);
      }),
      googleAutocompleteClass: computed(() => {
        let className = 'w-full transition';
        if (isSubmitting.value) {
          className += ' bg-gray-300';
        }
        return className;
      }),
      addressChange({ newVal }, id) {
        if (!newVal.trim()) {
          const field = id.split('-address-input')[0];
          thirdParty.value = {
            ...thirdParty.value,
            [`${field}AddressLineOne`]: '',
            [`${field}City`]: '',
            [`${field}State`]: '',
            [`${field}Zipcode`]: '',
            [`${field}Country`]: '',
          };
        }
      },
      getAddressData(address, data, input) {
        const field = input.split('-address-input')[0];
        thirdParty.value = {
          ...thirdParty.value,
          [`${field}AddressLineOne`]: `${address['street_number'] || ''} ${address['route'] || ''}`,
          [`${field}City`]: address['locality'] || '',
          [`${field}Zipcode`]: address['postal_code'] || '',
          [`${field}State`]: address['administrative_area_level_1'] || '',
          [`${field}Country`]: address['country'],
        }
      },
      async removeThirdParty() {
        if (props.id) {
          if (confirm('Would you like to remove this third party?')) {
            try {
              isSubmitting.value = true;
              await store.dispatch(
                'saveThirdPartyForClaim',
                { ...thirdParty.value, archive: true, tenant: tenantId },
              );
              await store.dispatch(
                'getThirdPartiesForClaim',
                { claimId: store.state.claim.id, tenantId }
              );
            } catch(err) {
              showMessage('Failed to remove third party', true);
            } finally {
              isSubmitting.value = false;
              emit('close');
            }
          }
        } else {
          store.commit('setItem', {
            key: 'addThirdPartyEnabled',
            value: false,
          });
        }
      },
      async saveThirdParty() {
        isSubmitting.value = true;
        try {
          await store.dispatch(
            'saveThirdPartyForClaim',
            {
              ...thirdParty.value,
              type: thirdPartyType.value,
              tenant: tenantId,
            },
          );
          await store.dispatch(
            'getThirdPartiesForClaim',
            { claimId: store.state.claim.id, tenantId }
          );
        } catch(err) {
          showMessage('Failed to save third party', true);
        } finally {
          isSubmitting.value = false;
          if (!props.id) {
            store.commit('setItem', {
              key: 'addThirdPartyEnabled',
              value: false,
            });
          }
          emit('close');
        }
      },
      fields: [
        {
          label: 'First Name',
          key: 'firstName',
          type: 'text',
          placeholder: 'Enter First Name',
        },
        {
          label: 'Last Name',
          key: 'lastName',
          type: 'text',
          placeholder: 'Enter Last Name',
        },
        {
          label: 'Contact Email',
          key: 'email',
          type: 'text',
          placeholder: 'Enter Email',
        },
        {
          label: 'Contact Phone Number',
          key: 'phone',
          type: 'text',
          placeholder: 'Enter Phone Number',
        },
        {
          label: 'Was this person injured?',
          key: 'injured',
          type: 'checkbox',
        },
        {
          key: 'injuredPlaceholder',
          type: 'placeholder',
        },
        {
          label: 'Third Party Policy Number',
          key: 'policy',
          type: 'text',
          placeholder: 'Enter Third Party Policy Number',
        },
        {
          label: 'Third Party Insurance Carrier',
          key: 'carrier',
          type: 'text',
          placeholder: 'Enter Name of Insurance Carrier',
        },
        {
          label: 'Police Department',
          placeholder: 'Enter Name of Police Department',
          type: 'text',
          key: 'policeDepartment'
        },
        {
          label: 'Police Report Case Number',
          key: 'policeCaseNumber',
          type: 'text',
          placeholder: 'Enter Case Number of Police Report',
        },
        {
          label: 'Third Party Address',
          key: 'location',
          type: 'address',
          placeholder: 'Enter Address Line 1',
        },
        {
          label: 'Line 2',
          key: 'locationAddressLineTwo',
          type: 'text',
          placeholder: 'Apt #'
        },
        {
          label: 'Driver License Address',
          key: 'license',
          type: 'address',
          placeholder: 'Enter Address Line 1'
        },
        {
          label: 'Line 2',
          key: 'licenseAddressLineTwo',
          type: 'text',
          placeholder: 'Apt #'
        },
        {
          label: 'Driver License Number',
          key: 'licenseNumber',
          type: 'text',
          placeholder: 'Enter DL #',
        },
        {
          label: 'Third Party Vehicle VIN',
          key: 'vehicleVin',
          type: 'text',
          placeholder: 'Enter VIN of Insured Vehicle'
        },
        {
          label: 'Description of damage / injuries',
          key: 'description',
          placeholder: 'Please describe the damage',
          type: 'textarea',
          span: true
        },
        {
          key: 'descriptionPlaceholder',
          type: 'placeholder',
          hidden: true,
        },
      ],
      thirdPartyRoles: computed(() => {
        return [
          {
            label: 'Third Party Driver',
            key: 'driver',
          },
          {
            label: 'Pedestrian',
            key: 'pedestrian',
          },
          {
            label: 'Passenger (of Insured)',
            key: 'passenger-insured',
          },
          {
            label: 'Passenger (of Third Party)',
            key: 'passenger-third-party',
          },
          {
            label: 'Witness',
            key: 'witness',
          },
          {
            label: 'Other',
            key: 'other',
          }
        ].filter(tp => {
          if ('mode' in tp) {
            return mode.includes(tp.mode);
          }
          return true;
        })
      })
    }
  }
}
</script>
