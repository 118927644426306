<template>
  <div class="p-4">
    <div ref="container" />
    <div class="
      hidden transition-all opacity-100
      duration-1000 -mt-12"
      ref="captchaRef" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { mountFrontend } from '@getaddify/lula-components';
import { Subject } from 'rxjs';
import { customerDevelopmentToken, tenantDevelopmentToken } from './developmentAuth'

const CURRENT_VIEW = 'claims-form';

const ADJUSTER_CODES = [
  {
    code: '01',
    label: 'Underage Driver',
  },
  {
    code: '02',
    label: 'Unauthorize Driver',
  },
  {
    code: '03',
    label: 'Used for Turo/HyreCar/Getaround (Car sharing platform)',
  },
]

const ADJUSTERS = [
  {
    "id": "e71ed087-e7ce-4253-9bac-839c183a3f68",
    "firstName": "Nathan",
    "lastName": "Hitchcock",
    "title": "Team Manager",
    "email": "Nathan.Hitchcock@choosebroadspire.com",
    "phone": "(469) 654-3583",
    "timezone": "America/Chicago"
  },
  {
    "id": "7308123d-50d1-46d4-b2c1-978903e3fce4",
    "firstName": "Ronald",
    "lastName": "Kennedy",
    "title": "Claims Examiner",
    "email": "Ronald.Kennedy@choosebroadspire.com",
    "phone": "(225) 612-2374",
    "timezone": "America/Chicago"
  }
];
const NEXT_STEPS = `
  Your claim submission will be reviewed by the Lula Team for completeness and accuracy, and the information will be forwarded to our external claims adjusters. (Lula does not process claims)|When a claim adjuster is assigned, you will receive an email with their information and the claim number.  This will also be available in your claims portal.|The claim adjuster will be your primary point of contact for your claim. They will request any additional information needed to process your claim. Any questions concerning your claim or obtaining status should be directed to the adjuster handling your claim.|The goal of the claim adjuster is to process all claims within 30-45 days after submission. This time frame can vary depending on the cooperation of all parties and the timely submission of any requested information.
`;

export default {
  setup() {

    const container = ref(null);
    const captchaRef = ref(null);

    const tokenSubject = new Subject();
    window.onloadCallback = function() {
      grecaptcha.render(captchaRef.value, {
        sitekey : process.env.VUE_APP_RECAPTCHA_SITEKEY,
        callback(token) {
          if (token) {
            tokenSubject.next(token);
            captchaRef.value.classList.remove('opacity-100');
            captchaRef.value.classList.add('opacity-0');
            captchaRef.value.classList.add('w-0');
          }
        }
      });
    };

    onMounted(async () => {

      const vehicleRequest = await fetch('/vehicles.json');
      const vehicles = await vehicleRequest.json();

      const driverRequest = await fetch('/drivers.json');
      const drivers = await driverRequest.json();

      const companiesRequest = await fetch('/companies.json');
      const companies = await companiesRequest.json();

      function mount(key) {
        switch(key) {
          case 'claims-grid-admin': {
            return mountFrontend('claims-grid-admin', container.value, {
              mode: 'admin_cars',
              environment: 'development',
              launchDarklyApiKey: process.env.VUE_APP_LAUNCHDARKLY_APIKEY,
              vehicles,
              companies,
              userId: `dev_${(Math.random() + 1).toString(36).substring(7)}`,
              userEmail: process.env.VUE_APP_GRAPHEMAIL,
              //vehicleId: 'fcc94a1c-f151-4125-a69c-9d72dd4f26f6',
              //tenantId: '828c79b7-0092-4c42-8878-339478676644',
              graphUrl: process.env.VUE_APP_GRAPHURL,
              graphHeaders: {
                'Authorization': `Bearer ${tenantDevelopmentToken()}`
              },
              selectClaim(evt, claim) {
                console.log(evt, claim);
              },
              onMounted(claims) {
                console.log('onMounted', claims);
              }
            });
          }
          case 'claims-grid': {
            return mountFrontend('claims-grid', container.value, {
              environment: 'development',
              mode: 'admin_cars_thirdparty',
              vehicles,
              companies,
              userId: `dev_${(Math.random() + 1).toString(36).substring(7)}`,
              userEmail: process.env.VUE_APP_GRAPHEMAIL,
              //vehicleId: 'fcc94a1c-f151-4125-a69c-9d72dd4f26f6',
              tenantId: process.env.VUE_APP_DEVELOPMENT_CUSTOMER_ID,
              graphUrl: process.env.VUE_APP_GRAPHURL,
              graphHeaders: {
                'Authorization': `Bearer ${customerDevelopmentToken()}`
              },
              twilioAccountSid: process.env.VUE_APP_TWILIO_ACCOUNTSID,
              twilioAPiKey: process.env.VUE_APP_TWILIO_ApiKey,
              selectClaim(evt, claim) {
                console.log(evt, claim);
              },
              onMounted(claims) {
                console.log('onMounted', claims);
              }
            });
          }
          case 'claims-form':
          default: {
            const mode = 'admin_cars';
            return mountFrontend('claims-form', container.value, {
              //captchaToken: tokenSubject,
              environment: 'development',
              launchDarklyApiKey: process.env.VUE_APP_LAUNCHDARKLY_APIKEY,
              mapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_APIKEY,
              graphUrl: process.env.VUE_APP_GRAPHURL,
              userId: `dev_${(Math.random() + 1).toString(36).substring(7)}`,
              userEmail: process.env.VUE_APP_DEVELOPMENT_CUSTOMER_EMAIL,
              twilioAccountSid: process.env.VUE_APP_TWILIO_ACCOUNTSID,
              twilioApiKey: process.env.VUE_APP_TWILIO_APIKEY,
              graphHeaders: {
                'Authorization': `Bearer ${mode.includes('admin') ? tenantDevelopmentToken() : customerDevelopmentToken()}`
              },
              showMessage(message, error = false) {
                alert(`${error ? 'Error:\n' : 'Message\n'}${message}`)
              },
              onClaimSubmit(claim) {
                console.log('onClaimSubmit', claim);
              },
              onMounted(claim) {
                console.log('onMounted', claim);
              },
              onViewVehicle(vehicle) {
                console.log(vehicle);
              },
              onPolicyClicked(policyId){
                window.open("https://paddocks.getaddify-staging.com/policies/0Of4zppPArxNFE4f7uI6", '_blank');
              },
              adjustersJson: JSON.stringify(ADJUSTERS),
              adjusterCodesJson: JSON.stringify(ADJUSTER_CODES),
              tenantId: '944a1440-d60f-44b1-9310-5ec11a3175cb',
              claimId: null,//'20fe5464-26b6-4a2b-aadb-5afb65dfa0cc',
              claimUrl: '/claims/{{claimId}}',
              nextSteps: NEXT_STEPS,
              mode,
              account: {
                'businessLegalName': 'YSL Slime Rentals',
                'contactEmail': 'rajesh@getaddify.com',
                'contactFirstName': 'rajesh',
                'contactLastName': 'kandepu',
                'contactNumber': '+13184973686',
                'tenantId': 'carsync',
              },
              vehicles,
              drivers,
            });
          }
        }
      }

      if (container.value) {
        mount(CURRENT_VIEW);
      }

    });

    return {
      container,
      captchaRef,
    }

  }
}
</script>
